.background {
    width: 100%;
    height: 100%;
    background-image: url("./image/background.png");
    background-position: center;
    background-size: cover;
    /*filter: blur(10px);*/
    overflow: hidden;
}

.pic1 {
    width: 100%;
    height: 100%;
    background-image: url("./image/pic1.svg");
    background-position: inherit;
    background-size: cover;
    filter: blur(2px);
    overflow: hidden;
}
.listStyle{
    color: #ffffff;
    font-size: 22px;
    line-height: 40px;
    margin-top: 20px;
    width: 1200px;
}
.scrollBar{
    height: 780px;
    overflow-y: scroll;
    position: absolute;
    left: 55px;
    top:30px;
}
.scrollBar::-webkit-scrollbar{
    display: none;
} 