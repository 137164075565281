#carousel_page7 {
  height: 100% !important;

  .carousel_box,
  .slick-slider,
  .slick-track,
  .slick-list,
  .slick-slide > div {
    height: 100% !important;
  }

  .carousel_box {
    display: grid !important;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    // grid-template-columns: auto auto;
  }

  .img_box {
    // padding: 4px 20px;
  }

  .img_box > img {
    width: 120px;
    height: 80px;
    object-fit: contain;
    background: #ffffff;
    border-radius: 12px;
    aspect-ratio: 18/9;
  }

  .title {
    padding: 4px;
    color: white;
    font-weight: bold;
    font-size: 18px;
  }

  // 调试
  //.slick-slide {
  //  &:nth-child(odd) {
  //    background-color: aqua;
  //  }
  //
  //  &:nth-child(even) {
  //    background-color: fuchsia;
  //  }
  //}
}
