.background {
    width: 100%;
    height: 100%;
    background-image: url("./image/background.png");
    background-position: center;
    background-size: cover;
    /*filter: blur(10px);*/
    overflow: hidden;
}

.pic1 {
    width: 100%;
    height: 100%;
    background-image: url("./image/pic1.svg");
    background-position: inherit;
    background-size: cover;
    filter: blur(2px);
    overflow: hidden;
}
.picBox{
    display: flex;
    flex-wrap: wrap
}
.picBox img{
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 12px;
    aspect-ratio: 18/9;
    margin-right: 10px
}
.scrollBar{
    height: 780px;
    overflow-y: scroll;
    position: absolute;
    left: 55px;
    top:30px;
}
.scrollBar::-webkit-scrollbar{
    display: none;
} 