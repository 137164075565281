.background {
    width: 100%;
    height: 100%;
    background-image: url("./image/background.png");
    background-position: center;
    background-size: cover;
    /*filter: blur(10px);*/
    overflow: hidden;
}
:global{
    #page9 .dv-scroll-board .rows .row-item{
        font-size: 20px !important
    }
    #page9 .dv-scroll-board .header{
        font-size: 22px !important;
        padding: 8px 0
    }
}
