.background {
    width: 100%;
    height: 100%;
    background-image: url("./image/background.jpg");
    background-position: center;
    background-size: cover;
    filter: blur(10px);
    overflow: hidden;
}

.flip_numbers_background {
    border-image-source: url('./image/border.png');
    border-image-slice: 10 16 15 10;
    border-image-width: 10px 16px 15px 10px;
    margin: 4px;
}

.flip_non_numbers_background {
    width: 25px;
    text-align: center;
}