.background {
    width: 100%;
    height: 100%;
    background-image: url("./image/background.png");
    background-position: center;
    background-size: cover;
    /*filter: blur(10px);*/
    overflow: hidden;
}

.pic1 {
    width: 100%;
    height: 100%;
    background-image: url("./image/pic1.svg");
    background-position: inherit;
    background-size: cover;
    filter: blur(2px);
    overflow: hidden;
}
