#headline_box {
  .headline_bkg {
    background-image: url("./image/pic.png");
    background-size: 100% 100%;
    opacity: 0.8;
  }

  .headline {
    color: rgba(35, 183, 229, 0.6);
    // font-size: 46px;
  }
}