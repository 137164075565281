#Page10 {
  background-image: url("./image/background.png");

  .人才基本信息汇总 {
    .background_image {
      position: absolute;
      background-image: url("./image/image1.png");
      background-size: 100% 100%;
      opacity: 0.8;
    }

    .target_box {
      position: absolute;
      top: -20px;
      // 孩子剧中
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;

      .target {
        font-size: 64px;
        color: #FFFFFFFF;
        text-shadow: #FFFFFFFF 0 0 0.1em,
        #FFFFFFFF 0 0 0.2em,
        #FFFFFFFF 0 0 0.3em,
        #000000FF 0 0 0.4em,
        #23B7E5FF 0 0 0.6em,
        #23B7E5FF 0 0 0.8em,
        #23B7E5FF 0 0 1em,
        #23B7E5FF 0 0 1.2em,
        #23B7E5FF 0 0 1.4em;
      }
    }
  }

  .bubble {
    width: 135px;
    aspect-ratio: 1;
    background-image: url("./image/bubble.svg");
    background-position: center;
    background-size: cover;

    // 孩子剧中
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      color: white;
    }

    &:first-child {
      .title {
        font-size: 28px;
      }
    }

    &:not(:first-child) {
      .title {
        font-size: 30px;
      }
    }
  }
}
